import * as React from "react";
import * as ReactDom from 'react-dom/client';
import { Configuration } from "../common";
import { ConfigurationState } from "@meta-web/configuration-durable-object";

import styles from "./ConfigurationInstance.css";
import {
  addConfigStorageV0Provider,
  ConfigStorageV0Provider,
  ConfigV0Payload,
  JSONData,
  removeConfigStorageV0Provider
} from "@meta-web/configstorage-v0";
import { updateConfiguration } from "./configuration";
import { addActivatableScriptV0Callback, removeActivatableScriptV0Callback } from "@meta-web/activatablescript-v0";

export class ConfigurationInstance {
  private id: string | null = null;
  private ownedBySelf: boolean = false;
  private container: HTMLDivElement;
  private thisScript: HTMLScriptElement;
  private provider!: ConfigStorageV0Provider;

  private entries: { [key: string]: ConfigV0Payload } = {};
  private root: ReactDom.Root;

  private visible = false;
  private activateCallback!: () => void;

  constructor(thisScript: HTMLScriptElement) {
    this.thisScript = thisScript;
    this.container = document.createElement('div');
    document.body.appendChild(this.container);

    this.root = ReactDom.createRoot(this.container);
    this.render();
  }

  private render() {
    this.root.render(
      <div className={[styles.configurationInstance, this.visible ? styles.visible : styles.hidden].join(" ")}>
        <h4>Remote Config Storage</h4>
        <div>Id: {this.id}</div>
        <button onClick={() => {
          this.entries = {};
          this.saveEntries();
        }}>Delete All
        </button>
        <h5>Entries</h5>
        {Object.keys(this.entries).map((key) => {
          const entry = this.entries[key];
          return <div key={key}>{key}: {JSON.stringify(entry)}
            <button onClick={() => {
              this.deleteKey(key);
            }}>Delete
            </button>
          </div>
        })}
      </div>
    );
  }

  private deleteKey(key: string): boolean {
    if (this.entries[key] !== undefined) {
      delete this.entries[key];
      this.saveEntries();
      return true;
    }
    return false;
  }

  private async saveEntries() {
    if (!this.ownedBySelf) {
      console.warn("Attempted to save entries when not owned by self");
      return;
    }
    this.render();
    const response = await updateConfiguration(this.id!, {
      configuration: {
        entries: this.entries,
      },
    });
    if (response) {
      console.log("response", response);
    }
  }

  applyConfigurationState(configurationState: ConfigurationState<Configuration>) {
    if (this.id) {
      console.warn("Config state already set");
      return;
    }
    console.log("applyConfigurationState", configurationState);
    this.id = configurationState.id;
    this.ownedBySelf = configurationState.ownedByCurrentUser;

    const configuration = configurationState.configuration;

    if (configuration.entries) {
      for (const key in configuration.entries) {
        // TODO - could validate that the entry is valid (otherwise this loop isn't necessary)
        this.entries[key] = configuration.entries[key];
      }
    }
    this.render();

    this.provider = {
      identity: this.thisScript,
      get: (key: string): { data: JSONData; } | null => {
        return this.entries[key];
      },
      getAll: (): { [key: string]: ConfigV0Payload; } => {
        return this.entries;
      },
      delete: (key: string): boolean => {
        return this.deleteKey(key);
      },
      save: (key: string, payload: ConfigV0Payload): void => {
        this.entries[key] = payload;
        this.saveEntries();
      }
    }
    addConfigStorageV0Provider(this.provider);

    this.activateCallback = () => {
      this.visible = !this.visible;
      this.render();
    }
    addActivatableScriptV0Callback(this.thisScript, this.activateCallback);
  }

  dispose() {
    this.root.unmount();
    removeConfigStorageV0Provider(this.provider);
    removeActivatableScriptV0Callback(this.thisScript, this.activateCallback);
  }
}