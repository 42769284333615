// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DHhh5NqLV1WCeh2HZlww {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: pink;\n}\n\n.FwF6RP8mKcUncW2B2u1A {\n    display: block;\n}\n\n.fMt92nHNX7A1J5OCiQgo {\n    display: none;\n}", "",{"version":3,"sources":["webpack://./src/client/ConfigurationInstance.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".configuration-instance {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: pink;\n}\n\n.visible {\n    display: block;\n}\n\n.hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configuration-instance": "DHhh5NqLV1WCeh2HZlww",
	"configurationInstance": "DHhh5NqLV1WCeh2HZlww",
	"visible": "FwF6RP8mKcUncW2B2u1A",
	"hidden": "fMt92nHNX7A1J5OCiQgo"
};
export default ___CSS_LOADER_EXPORT___;
